import {HttpClient} from "aurelia-http-client";

export class BodymapService {
    wounds;

    async getWounds() {
        if (!this.wounds) {
            this.wounds = await this.fetchWounds()
        }

        return this.wounds;
    }

    async fetchWounds() {
        const httpClient = new HttpClient();
        const woundsRequest = await httpClient.get("./config/body-map-wounds.json?_=" + new Date().valueOf());
        let wounds;

        try {
            const response = JSON.parse(woundsRequest.response);

            wounds = response.wounds || [];
        } catch (e) {
            wounds = [];
        }

        return wounds;
    }
}
