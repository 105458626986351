import {AnalyzerSEMPA} from "./sempa";
import {ISempaResult, ISempaRow} from "./isempa-result";
import {ConfigService} from "../ConfigService";
import {QuestionnaireService} from "../QuestionnaireService";
import {Questionnaire, QuestionnaireResponse, Tools} from "../../classes/FhirModules/Fhir";
import {fhirEnums} from "../../classes/fhir-enums";
import {NitTools} from "../../classes/NursitTools";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {isNaN, parseInt} from "lodash";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const moment = require('moment');

export class AnalyzerSempaKids extends AnalyzerSEMPA {
    _version = "SEMPA-Kids";

    colorGrey = "#c9c9c9";
    colorGreen = 'green';
    colorYellow = 'rgb(150,150,0)';
    colorOrange = 'rgb(223,112,0)';
    colorRed = 'rgb(240,0,0)';
    colorDarkGey = '#aaaaaa';
    colorLightGrey = 'rgb(239 239 239)';

    protected getNCSK(patient: PatientItem, assessment: any): ISempaRow {
        const result: ISempaRow = {
            title: 'NCS-K',
            text1: '',
            button: '',
            text2: '',
            isRisk: false,
            sum: 0,
            color: undefined
        }

        let anamnesis = this._anamnesis;
        if (!assessment)
            assessment = patient.latestAssessment;

        if (!anamnesis) {
            // anamnesis COULD be undefined when not a full analysis is run but something like GetSpiSpider() is called
            const anamnesisQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(patient.anamnesisName);
            const anamnesisResponses = QuestionnaireService.GetResponsesOfType(patient, anamnesisQuestionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            anamnesis = anamnesisResponses[anamnesisResponses.length - 1];
        }

        //#region preflight check
        let errors = [];
        if (!patient) {
            errors.push('No Patient provided');
        }

        if (!anamnesis) {
            errors.push('No Anamnesis present');
        }

        if (!assessment) {
            errors.push('No Assessment present');
        }

        if (errors.length > 0) {
            if (ConfigService.Debug)
                console.warn('Error in creating NCS-K', errors);

            return undefined;
        }

        let CareITAn_Sempa_Kind_1001: string = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'CareITAn_Sempa_Kind_1001');
        if (!CareITAn_Sempa_Kind_1001) {
            errors.push('Could not get a value for "CareITAn_Sempa_Kind_1001" from anamnesis');
        } else {
            CareITAn_Sempa_Kind_1001 = CareITAn_Sempa_Kind_1001.toUpperCase();
        }

        const ensureItem = function (linkId): string {
            const item = QuestionnaireResponse.GetResponseItemByLinkId(assessment, linkId, false);
            if (!item) {
                errors.push(`Required Field with linkId "${linkId}" not existent in assessment!`);

                return undefined;
            }

            return QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, linkId);
        }

        const NIT_SVAs_00 = ensureItem('NIT_SVAs_00');
        const NIT_SVAs_01 = ensureItem('NIT_SVAs_01');
        const NIT_SVAs_03 = ensureItem('NIT_SVAs_03');
        const NIT_SVAs_05 = ensureItem('NIT_SVAs_05');
        const NIT_SVAs_100 = ensureItem('NIT_SVAs_100');
        const NIT_SVAsK_100 = ensureItem('NIT_SVAsK_100');
        const NIT_SVAs_201 = ensureItem('NIT_SVAs_201');
        const NIT_SVAs_202 = ensureItem('NIT_SVAs_202');
        const NIT_SVAsK_202 = ensureItem('NIT_SVAsK_202');
        const NIT_SVAs_300 = ensureItem('NIT_SVAs_300');
        const NIT_SVAsK_300 = ensureItem('NIT_SVAsK_300');
        const NIT_SVAs_301 = ensureItem('NIT_SVAs_301');
        const NIT_SVAs_400 = ensureItem('NIT_SVAs_400');
        const NIT_SVAs_403 = ensureItem('NIT_SVAs_403');
        const NIT_SVAs_405 = ensureItem('NIT_SVAs_405');
        const NIT_SVAs_500 = ensureItem('NIT_SVAs_500');
        const NIT_SVAs_503 = ensureItem('NIT_SVAs_503');
        const NIT_SVAs_506 = ensureItem('NIT_SVAs_506');
        const NIT_SVAsK_510 = ensureItem('NIT_SVAsK_510');
        const NIT_SVAsK_511 = ensureItem('NIT_SVAsK_511');
        const NIT_SVAs_600 = ensureItem('NIT_SVAs_600');
        const NIT_SVAsK_600 = ensureItem('NIT_SVAsK_600');
        const NIT_SVAs_601 = ensureItem('NIT_SVAs_601');
        const NIT_SVAs_700 = ensureItem('NIT_SVAs_700');
        const NIT_SVAsK_700 = ensureItem('NIT_SVAsK_700');
        const NIT_SVAsK_701 = ensureItem('NIT_SVAsK_701');
        const NIT_SVAs_702 = ensureItem('NIT_SVAs_702');
        const NIT_SVAs_703 = ensureItem('NIT_SVAs_703');
        const NIT_SVAs_801 = ensureItem('NIT_SVAs_801');
        const NIT_SVAs_902 = ensureItem('NIT_SVAs_902');
        const NIT_SVAs_1101 = ensureItem('NIT_SVAs_1101');
        const NIT_SVAs_1203 = ensureItem('NIT_SVAs_1203');
        const NIT_SVAsK_1301 = ensureItem('NIT_SVAsK_1301');

        if (errors.length > 0) {
            console.warn('Error in creating NCS-K', errors);

            return undefined;
        }
        //#endregion

        try {
            const isInArray = function (strings: string[], search: string): boolean {
                for (let i = 0; i < strings.length; i++)
                    strings[i] = strings[i].toUpperCase();
                search = search.toUpperCase();

                return strings.indexOf(search) > -1;
            }

            let sum = 0;    // resulting sum

            //#region block 1
            if (isInArray(['CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                // add the highest resulting value of 300 and 301
                // block 1.1
                let sum300 = 0;
                switch (NIT_SVAs_300) {
                    case 'NIT_SVAs_300_01':
                        sum300 = 1;
                        break;
                    case 'NIT_SVAs_300_02':
                        sum300 = 2;
                        break;
                    case 'NIT_SVAs_300_03':
                        sum300 = 3;
                        break;
                }

                // block 1.2
                let sum301 = 0;
                switch (NIT_SVAs_301) {
                    case 'NIT_SVAs_301_01':
                        sum301 = 1;
                        break;
                    case 'NIT_SVAs_301_02':
                        sum301 = 2;
                        break;
                    case 'NIT_SVAs_301_03':
                        sum301 = 3;
                        break;
                }

                sum += Math.max(sum300, sum301);
            }
            //#endregion

            //#region block 2
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02', 'CareITAn_Sempa_Kind_1001_03', 'CareITAn_Sempa_Kind_1001_04'], CareITAn_Sempa_Kind_1001)) {
                // NIT_SVAsK_300 -> sum
                // block 2.1
                switch (NIT_SVAsK_300) {
                    case 'NIT_SVAsK_300_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAsK_300_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAsK_300_03':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 3
            if (isInArray(['CareITAn_Sempa_Kind_1001_04', 'CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                //  NIT_SVAs_100 -> sum
                //  block 3.1
                switch (NIT_SVAs_100) {
                    case 'NIT_SVAs_100_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAs_100_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAs_100_03':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 4
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02', 'CareITAn_Sempa_Kind_1001_03'], CareITAn_Sempa_Kind_1001)) {
                // NIT_SVAsK_100 -> sum
                // block 4.1
                switch (NIT_SVAsK_100) {
                    case 'NIT_SVAsK_100_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAsK_100_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAsK_100_03':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 5
            if (isInArray(['CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {   // get max value of 600 and 601 to add to sum

                // block 5.1
                let sum600 = 0;
                switch (NIT_SVAs_600) {
                    case 'NIT_SVAs_600_01':
                        sum600 = 1;
                        break;
                    case 'NIT_SVAs_600_02':
                        sum600 = 2;
                        break;
                    case 'NIT_SVAs_600_03':
                        sum600 = 3;
                        break;
                }

                // block 5.2
                let sum601 = 0;
                switch (NIT_SVAs_601) {
                    case 'NIT_SVAs_601_01':
                        sum600 = 1;
                        break;
                    case 'NIT_SVAs_601_02':
                        sum600 = 2;
                        break;
                    case 'NIT_SVAs_601_03':
                        sum600 = 3;
                        break;
                }

                sum += Math.max(sum600, sum601);
            }
            //#endregion

            //#region block 6
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_01',
                'CareITAn_Sempa_Kind_1001_03', 'CareITAn_Sempa_Kind_1001_04'], CareITAn_Sempa_Kind_1001)) {
                // block 6.1
                switch (NIT_SVAsK_600) {
                    case 'NIT_SVAsK_600_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAsK_600_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAsK_600_03':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 7
            if (isInArray(['CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                // block 7.1
                switch (NIT_SVAs_202) {
                    case 'NIT_SVAs_202_01':
                    case 'NIT_SVAs_202_02':
                    case 'NIT_SVAs_202_03':
                        sum += 1;
                        break;

                    case 'NIT_SVAs_202_04':
                    case 'NIT_SVAs_202_05':
                    case 'NIT_SVAs_202_06':
                        sum += 2;
                        break;

                    case 'NIT_SVAs_202_07':
                    case 'NIT_SVAs_202_08':
                    case 'NIT_SVAs_202_09':
                    case 'NIT_SVAs_202_10':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 8
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02',
                'CareITAn_Sempa_Kind_1001_03', 'CareITAn_Sempa_Kind_1001_04'], CareITAn_Sempa_Kind_1001)) {
                // block 8.1
                switch (NIT_SVAsK_202) {
                    case 'NIT_SVAsK_202_01':
                    case 'NIT_SVAsK_202_02':
                    case 'NIT_SVAsK_202_03':
                        sum += 1;
                        break;
                    case 'NIT_SVAsK_202_04':
                    case 'NIT_SVAsK_202_05':
                    case 'NIT_SVAsK_202_06':
                        sum += 2;
                        break;
                    case 'NIT_SVAsK_202_07':
                    case 'NIT_SVAsK_202_08':
                    case 'NIT_SVAsK_202_09':
                    case 'NIT_SVAsK_202_10':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 9
            // block 9.1
            let sum400 = 0;
            switch (NIT_SVAs_400) {
                case 'NIT_SVAs_400_01':
                    sum400 = 1;
                    break;
                case 'NIT_SVAs_400_02':
                    sum400 = 2;
                    break;
                case 'NIT_SVAs_400_03':
                    sum400 = 3;
                    break;
            }

            // block 9.2
            let sum403 = 0;
            switch (NIT_SVAs_403) {
                case 'NIT_SVAs_403_01':
                    sum403 = 1;
                    break;
                case 'NIT_SVAs_403_02':
                    sum403 = 2;
                    break;
                case 'NIT_SVAs_403_03':
                    sum403 = 3;
                    break;
            }

            // block 9.3
            let sum405 = 0;
            switch (NIT_SVAs_405) {
                case 'NIT_SVAs_405_01':
                    sum405 = 1;
                    break;
                case 'NIT_SVAs_405_02':
                    sum405 = 2;
                    break;
                case 'NIT_SVAs_405_03':
                    sum405 = 3;
                    break;
            }

            sum += Math.max(sum400, sum403, sum405);
            //#endregion

            //#region block 10
            if (isInArray(['CareITAn_Sempa_Kind_1001_04', 'CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                // block 10.1
                let sum500 = 0;
                switch (NIT_SVAs_500) {
                    case 'NIT_SVAs_500_01':
                        sum500 = 1;
                        break;
                    case 'NIT_SVAs_500_02':
                        sum500 = 2;
                        break;
                    case 'NIT_SVAs_500_03':
                        sum500 = 3;
                        break;
                }

                // block 10.2
                let sum503 = 0;
                switch (NIT_SVAs_503) {
                    case 'NIT_SVAs_503_01':
                        sum503 = 1;
                        break;
                    case 'NIT_SVAs_503_02':
                        sum503 = 2;
                        break;
                    case 'NIT_SVAs_503_03':
                        sum503 = 3;
                        break;
                }

                // block 10.3
                let sum506 = 0;
                switch (NIT_SVAs_506) {
                    case 'NIT_SVAs_506_01':
                        sum506 = 1;
                        break;
                    case 'NIT_SVAs_506_02':
                        sum506 = 2;
                        break;
                    case 'NIT_SVAs_506_03':
                        sum506 = 3;
                        break;
                }

                // add max of 500,503,506
                sum += Math.max(sum500, sum503, sum506);
            }
            //#endregion

            //#region block 11
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02', 'CareITAn_Sempa_Kind_1001_03'], CareITAn_Sempa_Kind_1001)) {
                // add the _sum_ of NIT_SVAsK_510 and NIT_SVAsK_511
                let sum510_511 = (NIT_SVAsK_510 === 'NIT_SVAsK_510_00' ? 0 : 1) + (NIT_SVAsK_511 === 'NIT_SVAsK_511_00' ? 0 : 1);
                sum += sum510_511;
            }
            //#endregion

            //#region block 12
            switch (NIT_SVAs_201) {
                case 'NIT_SVAs_201_01':
                    sum += 1;
                    break;
                case 'NIT_SVAs_201_02':
                    sum += 2;
                    break;
                case 'NIT_SVAs_201_03':
                    sum += 3;
                    break;
            }
            //#endregion

            //#region block 13
            if (isInArray(['CareITAn_Sempa_Kind_1001_04', 'CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                // add max value of sum700, 702 and 703 to sum
                // 13.1
                let sum700 = 0;
                switch (NIT_SVAs_700) {
                    case 'NIT_SVAs_700_01':
                        sum700 = 1;
                        break;
                    case 'NIT_SVAs_700_02':
                        sum700 = 2;
                        break;
                    case 'NIT_SVAs_700_03':
                        sum700 = 3;
                        break;
                }

                // 13.2
                let sum702 = 0;
                switch (NIT_SVAs_702) {
                    case 'NIT_SVAs_702_01':
                        sum702 = 1;
                        break;
                    case 'NIT_SVAs_702_02':
                        sum702 = 2;
                        break;
                    case 'NIT_SVAs_702_03':
                        sum702 = 3;
                        break;
                }

                // 13.3
                let sum703 = 0;
                switch (NIT_SVAs_703) {
                    case 'NIT_SVAs_703_01':
                        sum703 = 1;
                        break;
                    case 'NIT_SVAs_703_02':
                        sum703 = 2;
                        break;
                    case 'NIT_SVAs_703_03':
                        sum703 = 3;
                        break;
                }

                // add to sum:
                sum += Math.max(sum700, sum702, sum703);
            }
            //#endregion

            //#region block 14
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02', 'CareITAn_Sempa_Kind_1001_03'], CareITAn_Sempa_Kind_1001)) {
                // add highest value of K700, K701
                // 14.1
                let sumK700 = 0;
                switch (NIT_SVAsK_700) {
                    case 'NIT_SVAsK_700_01':
                        sumK700 = 1;
                        break;
                    case 'NIT_SVAsK_700_02':
                        sumK700 = 2;
                        break;
                    case 'NIT_SVAsK_700_03':
                        sumK700 = 3;
                        break;
                }

                //14.2
                let sumK701 = 0;
                switch (NIT_SVAsK_701) {
                    case 'NIT_SVAsK_701_01':
                        sumK701 = 1;
                        break;
                    case 'NIT_SVAsK_701_02':
                        sumK701 = 2;
                        break;
                    case 'NIT_SVAsK_701_03':
                        sumK701 = 3;
                        break;
                }

                // add the max to sum:
                sum += Math.max(sumK700, sumK701);
            }
            //#endregion

            //#region block 15
            if (isInArray(['CareITAn_Sempa_Kind_1001_03', 'CareITAn_Sempa_Kind_1001_04', 'CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_05'], CareITAn_Sempa_Kind_1001)) {
                // add mac value of sum00, sum01, sum03
                // 15.1
                let sum00 = 0;
                switch (NIT_SVAs_00) {
                    case 'NIT_SVAs_00_01':
                        sum00 = 1;
                        break;
                    case 'NIT_SVAs_00_02':
                        sum00 = 2;
                        break;
                    case 'NIT_SVAs_00_03':
                        sum00 = 3;
                        break;
                }

                // 15.2
                let sum01 = 0;
                switch (NIT_SVAs_01) {
                    case 'NIT_SVAs_01_01':
                        sum01 = 1;
                        break;
                    case 'NIT_SVAs_01_02':
                        sum01 = 2;
                        break;
                    case 'NIT_SVAs_01_03':
                        sum01 = 3;
                        break;
                }

                // 15.3
                let sum03 = 0;
                switch (NIT_SVAs_03) {
                    case 'NIT_SVAs_03_01':
                        sum03 = 1;
                        break;
                    case 'NIT_SVAs_03_02':
                        sum03 = 2;
                        break;
                    case 'NIT_SVAs_03_03':
                        sum03 = 3;
                        break;
                }

                // add max value:
                sum += Math.max(sum00, sum01, sum03);
            }
            //#endregion

            //#region block 16
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02'], CareITAn_Sempa_Kind_1001)) {
                // 16.1
                switch (NIT_SVAs_05) {
                    case 'NIT_SVAs_05_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAs_05_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAs_05_03':
                    case 'NIT_SVAs_05_04':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            //#region block 17
            if (isInArray(['CareITAn_Sempa_Kind_1001_04', 'CareITAn_Sempa_Kind_1001_05', 'CareITAn_Sempa_Kind_1001_06'], CareITAn_Sempa_Kind_1001)) {
                // add highest value of 902, 1101, 1203, 801

                // 17.1
                let sum902 = 0;
                switch (NIT_SVAs_902) {
                    case 'NIT_SVAs_902_01':
                        sum902 = 1;
                        break;
                    case 'NIT_SVAs_902_02':
                        sum902 = 2;
                        break;
                    case 'NIT_SVAs_902_03':
                        sum902 = 3;
                        break;
                }

                // 17.2
                let sum1101 = 0;
                switch (NIT_SVAs_1101) {
                    case 'NIT_SVAs_1101_01':
                        sum1101 = 1;
                        break;
                    case 'NIT_SVAs_1101_02':
                        sum1101 = 2;
                        break;
                    case 'NIT_SVAs_1101_03':
                        sum1101 = 3;
                        break;
                }

                // 17.3
                let sum1203 = 0;
                switch (NIT_SVAs_1203) {
                    case 'NIT_SVAs_1203_01':
                        sum1203 = 1;
                        break;
                    case 'NIT_SVAs_1203_02':
                        sum1203 = 2;
                        break;
                    case 'NIT_SVAs_1203_03':
                        sum1203 = 3;
                        break;
                }
                // 17.4
                let sum801 = 0;
                switch (NIT_SVAs_801) {
                    case 'NIT_SVAs_801_01':
                        sum801 = 1;
                        break;
                    case 'NIT_SVAs_801_02':
                        sum801 = 2;
                        break;
                    case 'NIT_SVAs_801_03':
                        sum801 = 3;
                        break;
                }

                // add the max value to sum:
                sum += Math.max(sum801, sum1203, sum1101, sum902);
            }
            //#endregion

            //#region block 18
            if (isInArray(['CareITAn_Sempa_Kind_1001_01', 'CareITAn_Sempa_Kind_1001_02', 'CareITAn_Sempa_Kind_1001_03'], CareITAn_Sempa_Kind_1001)) {
                // 18.1
                switch (NIT_SVAsK_1301) {
                    case 'NIT_SVAsK_1301_01':
                        sum += 1;
                        break;
                    case 'NIT_SVAsK_1301_02':
                        sum += 2;
                        break;
                    case 'NIT_SVAsK_1301_03':
                        sum += 3;
                        break;
                }
            }
            //#endregion

            result.sum = sum;
            result.level = 0;
            result.color = this.colorGrey;

            if (sum >= 1 && sum <= 7) {
                result.level = 1;
                result.color = this.colorGreen;
            } else if (sum >= 8 && sum <= 16) {
                result.level = 2;
                result.color = this.colorYellow;
            } else if (sum >= 17 && sum <= 25) {
                result.level = 3;
                result.color = this.colorOrange;
            } else if (sum >= 26) {
                result.color = this.colorRed;
                result.level = 4;
            }

            result.text1 = this.i18n.tr('ncs_k_analysis_' + result.level);
            result.info = this.i18n.tr('ncs_k_info_' + result.level);
            ///////////
        } catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    protected generateHtmlScores(values: ISempaResult): string {
        if (!values) {
            const msg = "No values provided for generting html scores";
            if (ConfigService.Debug && !ConfigService.IsTest)
                console.warn(msg);

            return `<div data-reason="${msg}"></div>`;
        }

        const ncsk = this.getNCSK(this.patient, this.patient.latestAssessment);
        let result: string = `<div class="row" id="htmlScoresParent">`;
        if (ncsk) {
            result += `<span style="color:${ncsk.color}">${ncsk.text1}</span>\n`;
        }
        result += `</div>`;

        return result;
    }

    protected mapKUSSValues(kussResponse: any, kussQuestionnaire: any, assessment: any, painValue : string) {
        if (!assessment)
            assessment = this.patient.latestAssessment;

        if (!kussResponse || !assessment || kussResponse.status !== 'in-progress')
            return;


        const mapByIntId = (sourceLinkId: string, targetLinkId: string): number => {
            // 1st get the last part of the answer:
            const itemSource = QuestionnaireResponse.GetResponseItemByLinkId(assessment, sourceLinkId, false);
            const itemTarget = QuestionnaireResponse.GetResponseItemByLinkId(kussResponse, targetLinkId, false);
            const qrItemKuss = Questionnaire.GetQuestionnaireItemByLinkId(kussQuestionnaire, targetLinkId);

            if (!itemSource || !itemTarget)
                return;
            const intValueSource = QuestionnaireResponse.GetResponseItemValueInt(itemSource, 0);
            const code = `${targetLinkId}_${NitTools.ToString(intValueSource, 2, '0')}`;

            itemTarget.answer = [
                {
                    valueCoding: {
                        code: code,
                        display: QuestionnaireResponse.GetOptionText(code, qrItemKuss)
                    }
                }
            ]

            return QuestionnaireResponse.GetOrdinalValue(code, qrItemKuss);
        }

        let sum = 0;
        // Weinen: NIT_SVAsK_207 -> CareITKUSS_100
        sum += mapByIntId('NIT_SVAsK_207', 'CareITKUSS_100');

        // Gesichtsausdruck: NIT_SVAsK_208 -> CareITKUSS_101
        sum += mapByIntId('NIT_SVAsK_208', 'CareITKUSS_101');

        // Rumpfhaltung: NIT_SVAsK_209 -> CareITKUSS_102
        sum += mapByIntId('NIT_SVAsK_209', 'CareITKUSS_102');

        // Beinhaltung: NIT_SVAsK_210 -> CareITKUSS_103
        sum += mapByIntId('NIT_SVAsK_210', 'CareITKUSS_103');

        // Unruhe: NIT_SVAsK_211 -> CareITKUSS_104
        sum += mapByIntId('NIT_SVAsK_211', 'CareITKUSS_104');

        // Schmerzen: NIT_SVAsK_202 -> CareITKUSS_106
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'CareITKUSS_105'))
        sum += mapByIntId(painValue, 'CareITKUSS_106');

        // Summe -> CareITKUSS_105
        const itemCareITKUSS_105 = QuestionnaireResponse.GetResponseItemByLinkId(kussResponse, 'CareITKUSS_105');
        itemCareITKUSS_105.answer = [
            {
                valueInteger: sum
            }
        ];
    }

    protected mapSilvermanValues(): ISempaRow {
        const silverRow: ISempaRow = {
            title: 'Silverman Score',
            text1: '',
            text2: '',
            button: '',
            tag: 'silverman',
            isRisk: false,
            color: undefined,
        };

        try {
            const cfgSilverman = ConfigService.GetFormSettings('silverman');
            if (!cfgSilverman) {
                throw('No Config for route "silverman" found!');
            }

            if (!cfgSilverman.enabled)
                return undefined;

            const silvermanQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(cfgSilverman.questionnaireName);
            if (!silvermanQuestionnaire) {
                throw(`Questionnaire "${cfgSilverman.questionnaireName}" not found`);
            }

            let silverResponse = QuestionnaireResponse.GetAttachedResponseDirect(this.patient, this.patient.latestAssessment, cfgSilverman.questionnaireName);
            if (!silverResponse) {
                console.warn('No pre-created silverman-response found. consider setting it in assessment.autogenerate');
                silverResponse = Tools.SubstituteDefaultQRSkeleton(this.patient, silvermanQuestionnaire.id, QuestionnaireResponseStatus.inProgress);
            }

            if (!silverResponse) {
                console.error('Could not create a valid Silverman-Response');
                throw('Error creating response');
            }

            const silverItem105 = QuestionnaireResponse.GetResponseItemByLinkId(silverResponse, 'NIT_SILV_105', false);

            const assessment = QuestionnaireService.GetQuestionnaireDirect(this.patient.latestAssessment.questionnaire);
            const itemNIT_SVAs_201 = Questionnaire.GetQuestionnaireItemByLinkId(assessment, 'NIT_SVAs_201');
            const valueNIT_SVAs_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAs_201', undefined);

            silverRow.text1 = Questionnaire.GetItemHintTextById(itemNIT_SVAs_201, valueNIT_SVAs_201)

            const assessmentValueNIT_SVASK_218 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAsK_218', undefined);
            if (assessmentValueNIT_SVASK_218 && valueNIT_SVAs_201 !== 'NIT_SVAs_201_00') {
                silverRow.text1 += ' / ' + assessmentValueNIT_SVASK_218;
            }

            if (silverResponse && silverResponse.status === 'in-progress') {
                const silverMappings = [
                    {
                        assessment: 'NIT_SVASk_213',
                        silverman: 'NIT_SILV_100',
                        valueMappings: [
                            {assessment: 'NIT_SVAsK_213_00', silverman: 'NIT_SILV_100_00'},
                            {assessment: 'NIT_SVAsK_213_01', silverman: 'NIT_SILV_100_01'},
                            {assessment: 'NIT_SVAsK_213_02', silverman: 'NIT_SILV_100_02'}
                        ]
                    },
                    {
                        assessment: 'NIT_SVASk_214',
                        silverman: 'NIT_SILV_101',
                        valueMappings: [
                            {assessment: 'NIT_SVAsK_214_00', silverman: 'NIT_SILV_100_00'},
                            {assessment: 'NIT_SVAsK_214_01', silverman: 'NIT_SILV_101_01'},
                            {assessment: 'NIT_SVAsK_214_02', silverman: 'NIT_SILV_102_02'}
                        ]
                    },
                    {
                        assessment: 'NIT_SVASk_215',
                        silverman: 'NIT_SILV_102',
                        valueMappings: [
                            {assessment: 'NIT_SVAsK_215_00', silverman: 'NIT_SILV_102_00'},
                            {assessment: 'NIT_SVAsK_215_01', silverman: 'NIT_SILV_102_01'},
                            {assessment: 'NIT_SVAsK_215_02', silverman: 'NIT_SILV_102_02'}
                        ]
                    },
                    {
                        assessment: 'NIT_SVASk_216',
                        silverman: 'NIT_SILV_103',
                        valueMappings: [
                            {assessment: 'NIT_SVAsK_216_00', silverman: 'NIT_SILV_103_00'},
                            {assessment: 'NIT_SVAsK_216_01', silverman: 'NIT_SILV_103_01'},
                            {assessment: 'NIT_SVAsK_216_02', silverman: 'NIT_SILV_103_02'}
                        ]
                    },
                    {
                        assessment: 'NIT_SVASk_217',
                        silverman: 'NIT_SILV_104',
                        valueMappings: [
                            {assessment: 'NIT_SVAsK_217_00', silverman: 'NIT_SILV_104_00'},
                            {assessment: 'NIT_SVAsK_217_01', silverman: 'NIT_SILV_104_01'},
                            {assessment: 'NIT_SVAsK_217_02', silverman: 'NIT_SILV_104_02'}
                        ]
                    }
                ];

                let silverSum: number = 0;  // this is where we store the calculated sum

                for (const map of silverMappings) {
                    const valueAssessment = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, map.assessment); // the value in the assessment
                    const responseItemSilverman = QuestionnaireResponse.GetResponseItemByLinkId(silverResponse, map.silverman, false); // the response Item to store the silverman value
                    const itemSilverman = Questionnaire.GetQuestionnaireItemByLinkId(silvermanQuestionnaire, map.silverman); // the questionnaire-item to get the correct display text from

                    if (valueAssessment && responseItemSilverman && itemSilverman) {
                        let targetId = '';
                        for (const valueMap of map.valueMappings) {
                            if ((<string>valueAssessment).toUpperCase() === valueMap.assessment.toUpperCase()) {
                                targetId = valueMap.silverman;
                                break;
                            }
                        }

                        /*if (!targetId) {
                            console.warn(`No mapping for assessment<->silverman found for assessment value "${valueAssessment}"`);
                        } else { */
                        if (targetId) {
                            const coding: any = {
                                valueCoding: {
                                    code: targetId
                                }
                            };

                            let txt = QuestionnaireResponse.GetOptionText(targetId, itemSilverman);
                            if (!txt)
                                txt = Questionnaire.GetItemHintTextById(itemSilverman, targetId);

                            if (txt) coding.valueCoding.display = txt;

                            responseItemSilverman.answer = [coding];

                            let ordValue = QuestionnaireResponse.GetOrdinalValue(targetId, itemSilverman);
                            if (isNaN(ordValue)) {
                                ordValue = QuestionnaireResponse.GetKeyValueAsInteger(targetId);
                            }

                            silverSum += ordValue;
                        }
                    }
                }

                // write the result from silverSum into SILV_105:
                if (silverItem105) {
                    silverItem105.answer = [{valueInteger: silverSum}];
                }
            }

            if (silverItem105) {
                silverRow.text2 = QuestionnaireResponse.GetResponseItemValue(silverItem105, '-');
            }

            window['silvermanScoreClicked'] = async () => {
                await this.createPopup(this.patient, this.patient.latestAssessment, silvermanQuestionnaire.name, false);
            }

            silverRow.button = this.getButtonText('silvermanScoreClicked', 'Silverman-Score', 'silverman');
        } catch (e) {
            console.error(e);
            silverRow.text1 = `<b style="color:red">${e}</b>`;
        }

        return silverRow;
    }

    protected getFinneganTableRow(): ISempaRow {
        const finneganRow: ISempaRow = {
            title: 'Konsum/Mutter',
            color: undefined,
            button: '',
            isRisk: false,
            sum: 0,
            tag: 'finnegan',
            isValidated: false,
            text1: '',
            text2: ''
        };

        const finneganSettings = ConfigService.GetFormSettings('CareITKIDSFinnegan');
        if (!finneganSettings) {
            console.error('No route for "CareITKIDSFinnegan" found in config');
        } else {

            const finneganQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(finneganSettings.questionnaireName);
            const finneganResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, finneganQuestionnaire.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);

            const itemNIT_SVAnK_2007 = QuestionnaireResponse.GetResponseItemByLinkId(this._anamnesis, 'NIT_SVAnK_2007', false); // Konsum Mutter
            const valueNIT_SVAnK_2007 = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAnK_2007);
            const finneganIsRisk = valueNIT_SVAnK_2007 === 'NIT_SVAnK_2007_01';

            /* No more Focus Assessment Button for Finnegan #2549
            window['finneganScoreClicked'] = async () => {
                await this.createPopup(this.patient, this.patient.latestAssessment, finneganQuestionnaire.name, false);
            }

            finneganRow.button = finneganQuestionnaire ? this.getButtonText('finneganScoreClicked', 'Finnegan', finneganSettings.route) : `<label class="text-danger">Questionnaire ${finneganSettings.questionnaireName} nicht gefunden</label>`;
            */

            finneganRow.button = finneganQuestionnaire ? '' : `<label class="text-danger">Questionnaire ${finneganSettings.questionnaireName} nicht gefunden</label>`;
            finneganRow.sum = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(finneganResponse, 'CareITFinnegan_200', '0'));
            finneganRow.isValidated = (finneganResponse && ['amended', 'completed'].indexOf(finneganResponse.status) > -1);
            finneganRow.text2 = QuestionnaireResponse.GetResponseItemValueByLinkId(finneganResponse, 'CareITFinnegan_200', '0');
            finneganRow.text2 += this.getWarningMessage(finneganResponse);
            finneganRow.text1 = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAnK_2007);
            finneganRow.isRisk = finneganIsRisk;
        }

        return finneganRow;
    }

    protected getKUSS(): ISempaRow {
        //#region KUSS/Pain
        const kussConfig = ConfigService.GetFormSettings('CareITKIDSKUSS');
        const kussRow: ISempaRow = {
            title: 'Schmerzen/KUSS',
            color: undefined,
            button: '',
            isRisk: false,
            sum: 0,
            tag: 'kuss',
            isValidated: false,
            text1: '',
            text2: ''
        };

        if (!kussConfig) {
            console.warn('No route "CareITKIDSKUSS" found!');
        } else {
            const kussQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(kussConfig.questionnaireName);
            if (!kussQuestionnaire) {
                console.error(`Questionnaire with name "${kussConfig.questionnaireName}" not found for route "CareITKIDSKUSS"`)
            } else {
                let valueNIT_202_LinkId = 'NIT_SVAsK_202';
                let valueNIT_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, valueNIT_202_LinkId);
                if (!valueNIT_202) {
                    valueNIT_202_LinkId = 'NIT_SVAs_202';
                    valueNIT_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, valueNIT_202_LinkId, 'NIT_SVAsK_202_00');
                }
                const kussResponse = QuestionnaireResponse.GetAttachedResponseDirect(this.patient, this.patient.latestAssessment, kussQuestionnaire.name); // QuestionnaireService.GetLatestResponseOfType(this.patient, kussQuestionnaire.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.inProgress]);
                this.mapKUSSValues(kussResponse, kussQuestionnaire, this.patient.latestAssessment, valueNIT_202);

                let itemNIT_SVAsK_202 = QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, valueNIT_202_LinkId, false); // Schmerzen Questionnaire Item

                const KussHasPain = !valueNIT_202.endsWith('_00'); // it's pain when another value than 0 has been selected
                kussRow.text1 = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAsK_202);

                if (KussHasPain) {
                    //#region Getting the sum value (from the ordinal value of the selected options) from kuss_100 to kuss_104
                    const getKussOrdinalValue = function (linkId: string): number {
                        // CareITKUSS_100
                        const itemValue = QuestionnaireResponse.GetResponseItemValueByLinkId(kussResponse, linkId);
                        const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(kussQuestionnaire, linkId);
                        if (itemValue) {
                            const ordValue = QuestionnaireResponse.GetOrdinalValue(itemValue, questionnaireItem);
                            if (!isNaN(ordValue))
                                return ordValue;
                        }

                        return NaN;
                    }

                    const getKussOrdinalValueSum = function (): number {
                        const fieldNames = ['CareITKUSS_100', 'CareITKUSS_101', 'CareITKUSS_102', 'CareITKUSS_103', 'CareITKUSS_104'];
                        let result: number = 0;
                        for (const fieldName of fieldNames) {
                            const fieldValue = getKussOrdinalValue(fieldName);
                            if (isNaN(fieldValue)) {
                                return NaN;
                            } else {
                                result += fieldValue;
                            }
                        }

                        return result;
                    }
                    //#endregion

                    kussRow.button = kussQuestionnaire ? this.getButtonText('kussScoreClicked', 'KUSS', kussConfig.route) : `<label class="text-danger">Questionnaire ${kussConfig.questionnaireName} nicht gefunden</label>`;
                    kussRow.sum = getKussOrdinalValueSum(); // parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(kussResponse, 'CareITKUSS_105', '0'));

                    kussRow.isValidated = (kussResponse && ['amended', 'completed'].indexOf(kussResponse?.status) > -1);
                    kussRow.text2 = kussRow.sum.toString();
                    kussRow.text2 += this.getWarningMessage(kussResponse);
                    window['kussScoreClicked'] = async () => {
                        this.mapKUSSValues(kussResponse, kussQuestionnaire, this.patient.latestAssessment, valueNIT_202);
                        await this.createPopup(this.patient, this.patient.latestAssessment, kussQuestionnaire.name, false);
                    }
                } else {
                    kussRow.text1 = this.i18n.tr("sempa_kids_kuss_nopain");
                }
            }
        }
        //#endregion

        return kussRow;
    }

    protected getRelationRow(): ISempaRow {
        const qAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(this.assessmentName);
        const motherChildRelationshipRow: ISempaRow = {
            title: "Mutter-Kindverhältnis", // Column 0
            text1: this.i18n.tr("unknown"), // Column 1
            button: '', // Column 2
            text2: '', // // Column 3
            sum: 0,
            tag: "motherChildRelations",
            color: undefined,
            isRisk: false,
        }

        if (qAssessment) {
            const itemNIT_SVAsK_1301 = Questionnaire.GetQuestionnaireItemByLinkId(qAssessment, 'NIT_SVAsK_1301');
            if (itemNIT_SVAsK_1301) {
                const valueNIT_SVAsK_1301: string = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAsK_1301');
                if (valueNIT_SVAsK_1301) {

                    const txt = Questionnaire.GetItemHintTextById(itemNIT_SVAsK_1301, valueNIT_SVAsK_1301);
                    /*
                    const number = QuestionnaireResponse.GetOptionText(valueNIT_SVAsK_1301, itemNIT_SVAsK_1301);
                    if (txt.startsWith(number))
                        motherChildRelationshipRow.text1 = txt;
                    else
                        motherChildRelationshipRow.text1 = `${number} - ${txt}`; */
                    motherChildRelationshipRow.text1 = txt;
                }
            }
        }

        return motherChildRelationshipRow;
    }

    protected getMobility(): ISempaRow {
        const qAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(this.assessmentName);
        const mobilityRow: ISempaRow = {
            title: this.i18n.tr('sempa_kids_vitality_mobility'),
            text1: this.i18n.tr("unknown"),
            button: '',
            text2: '',
            sum: 0,
            isRisk: false,
            color: undefined,
            tag: 'childMobility'
        }

        let mobiTxt = '';
        let mobiNum = undefined;

        const valNIT_SVAsK_100 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAsK_100');
        if (valNIT_SVAsK_100) {
            const itemNIT_SVAsK_100 = Questionnaire.GetQuestionnaireItemByLinkId(qAssessment, 'NIT_SVAsK_100');
            if (itemNIT_SVAsK_100) {
                mobiTxt = Questionnaire.GetItemHintTextById(itemNIT_SVAsK_100, valNIT_SVAsK_100);
            }
        } else { // fall back to default sempa svas_100 field
            let valNIT_SVAs_100 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAs_100');
            const itemNIT_SVAs_100 = Questionnaire.GetQuestionnaireItemByLinkId(qAssessment, 'NIT_SVAs_100');

            if (!valNIT_SVAs_100 && itemNIT_SVAs_100) {
                // get the default value from the questionnaire
                const values = Questionnaire.GetDefaultQuestionnaireValues(qAssessment);
                valNIT_SVAs_100 = values.find(item => item.key === "NIT_SVAs_100")?.value;
            }

            if (valNIT_SVAs_100 && itemNIT_SVAs_100) {
                mobiTxt = Questionnaire.GetItemHintTextById(itemNIT_SVAs_100, valNIT_SVAs_100);
            }
        }

        mobilityRow.text1 = mobiTxt;

        return mobilityRow;
    }

    protected getSturz(): ISempaRow {
        const valNIT_SVAs_110 = QuestionnaireResponse.GetResponseItemValueByLinkId(this.patient.latestAssessment, 'NIT_SVAs_110');
        const sturzRow: ISempaRow = {
            title: this.i18n.tr("sempa_kids_fall"),
            tag: "fall",
            color: undefined,
            button: '',
            text1: '',
            text2: '',
            sum: 0,
            isRisk: false
        };

        let gcsWarningMessages: string[] = [];
        const gcsFrankfurtConfig = ConfigService.GetFormSettings('GCSFrankfurt');
        if (!gcsFrankfurtConfig) {
            console.error('No form setting for route "GCSFrankfurt" found!');
            gcsWarningMessages.push('GCSFrankfurt not configured');
        }
        const questionnaireFrankfurt = gcsFrankfurtConfig ? QuestionnaireService.GetQuestionnaireByNameDirect(gcsFrankfurtConfig.questionnaireName) : undefined;

        if (!questionnaireFrankfurt && gcsFrankfurtConfig) {
            console.error(`Questionnaire with name "${gcsFrankfurtConfig.questionnaireName}", configured in route "GCSFrankfurt", not found!`);
            gcsWarningMessages.push(`Questionnaire "${gcsFrankfurtConfig.questionnaireName}" not found!`);
        }

        const gcsRitzConfig = ConfigService.GetFormSettings('GCSRitz');
        if (!gcsRitzConfig) {
            console.error('No form setting for route "GCSRitz" found!');
            gcsWarningMessages.push('GCSRitz not configured');
        }

        const questionnaireRitz = gcsRitzConfig ? QuestionnaireService.GetQuestionnaireByNameDirect(gcsRitzConfig.questionnaireName) : undefined;
        if (!questionnaireRitz && gcsRitzConfig) {
            console.error(`Questionnaire with name "${gcsRitzConfig.questionnaireName}", configured in route "GCSRitz", not found!`);
            gcsWarningMessages.push(`Questionnaire "${gcsRitzConfig.questionnaireName}" not found!`);
        }

        if (valNIT_SVAs_110 === 'NIT_SVAs_110_00' || valNIT_SVAs_110 === 'NIT_SVAs_110_01') {
            sturzRow.text1 = this.i18n.tr('yes');
            if (gcsWarningMessages.length > 0) {
                sturzRow.button = `<div style="color:red">${gcsWarningMessages.join('<br />')}</div>`;
            } else {
                window['gcsFrankfurtClicked'] = async () => {
                    await this.createPopup(this.patient, this.patient.latestAssessment, questionnaireFrankfurt.name, false);
                }
                window['gcsRitzClicked'] = async () => {
                    await this.createPopup(this.patient, this.patient.latestAssessment, questionnaireRitz.name, false);
                }

                sturzRow.button = this.getButtonText('gcsFrankfurtClicked', 'GCS-Frank.', gcsFrankfurtConfig.route) +
                    ' <br />' +
                    this.getButtonText('gcsRitzClicked', 'GCS-Ritz', gcsRitzConfig.route);

                const ritzResponse = QuestionnaireResponse.GetAttachedResponseDirect(this.patient, this.patient.latestAssessment, questionnaireRitz.name);
                const frankfurtResponse = QuestionnaireResponse.GetAttachedResponseDirect(this.patient, this.patient.latestAssessment, questionnaireFrankfurt.name);
                const ritzSum = QuestionnaireResponse.GetResponseItemValueByLinkId(ritzResponse, 'CareITGCSnachRitz_201', '-');

                // have to decide to take which sum field. this is indicated by the age selection in the f-gcs response on CareITF_GCS_105.
                const CareITF_GCS_105 = QuestionnaireResponse.GetResponseItemValueByLinkId(frankfurtResponse, 'CareITF_GCS_105');
                const frankfurtSum = QuestionnaireResponse.GetResponseItemValueByLinkId(frankfurtResponse,
                    CareITF_GCS_105 === 'CareITF_GCS_105_00' ? 'CareITF_GCS_201' : 'CareITF_GCS_200', '-');


                sturzRow.text2 = `<div style="height: 42px">
                                 ${frankfurtSum} ${this.getWarningMessage(frankfurtResponse)}
                                 </div>
                                 <div>
                                 ${ritzSum} ${this.getWarningMessage(ritzResponse)}
                                 </div>`;
            }
        } else {
            sturzRow.text1 = this.i18n.tr('no');
        }

        return sturzRow;
    }

    protected static GetAgeRange(patient: PatientItem): IAgeRange {
        if (!patient)
            return IAgeRange.Other;

        // first try to get the value from the assessment ..
        const assessment = QuestionnaireService.GetLatestResponseOfName(patient, patient.getAssessmentName(), [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        let valueCareITAn_Sempa_Kind_1001 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'CareITAn_Sempa_Kind_1001');
        if (!valueCareITAn_Sempa_Kind_1001) {
            // .. if that did not work, get it from the anamnesis
            const anamnesis = QuestionnaireService.GetLatestResponseOfName(patient, patient.getAnamnesisName(), [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            if (anamnesis) {
                valueCareITAn_Sempa_Kind_1001 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'CareITAn_Sempa_Kind_1001');
            }
        }

        if (!valueCareITAn_Sempa_Kind_1001) {
            // when no anamnesis/assessment has been specified get the age range from the patient's birthdate
            if (typeof patient.years === "number" && !isNaN(patient.years)) {
                if (patient.years < 5) {
                    const parsedMoment = moment(patient.birthDate);
                    const days = parsedMoment.diff(new Date(), 'days');
                    if (days <= 14)
                        return IAgeRange.ZeroTo14Days;
                    else if (days >= 15 && patient.years < 1)
                        return IAgeRange.FifteenDaysTo1Year;
                    else
                        return IAgeRange.OneTo5Years;
                } else if (patient.years >= 5 && patient.years < 12)
                    return IAgeRange.FiveTo12Years;
                else if (patient.years >= 12 && patient.years <= 18)
                    return IAgeRange.TwelveTo18Years;
            }
        } else {
            // take the value from the response
            let val = <string>valueCareITAn_Sempa_Kind_1001;
            let varr = val.split('_');
            val = varr[varr.length - 1]; // now val should contain only the last number

            switch (val) {
                // 0-14 Tage
                case '01': // Geburtshilfe from Anamnesis
                case '02':
                    return IAgeRange.ZeroTo14Days;
                    break;
                // 15 Tage - 1 Jahr
                case '03':
                    return IAgeRange.FifteenDaysTo1Year;
                    break;
                // 1-5 Jahre
                case '04':
                    return IAgeRange.OneTo5Years;
                    break;
                // 5 - 12 Jahre
                case '05':
                    return IAgeRange.FiveTo12Years;
                    break;
                // 12 - 18 Jahre
                case '06':
                    return IAgeRange.TwelveTo18Years;
                    break;
                default:
                    return IAgeRange.Other;
                    break;
            }
        }

        return IAgeRange.Other;
    }

    protected getPAVK(): ISempaRow {
        const result: ISempaRow = {
            title: 'PAV-K',
            text1: this.i18n.tr('n_a'),
            text2: '',
            button: '',
            tag: 'pavk'
        };
        const anamnesis = this._anamnesis;

        try {
            const ensureItem = (linkId: string) => {
                const result = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, linkId);
                if (typeof result === "undefined") {
                    if (ConfigService.Debug)
                        console.warn(`No value for "${linkId}" or field not found`);
                }

                return result;
            }

            const getMultiSelectValues = (response: any, linkId: string): string[] => {
                const item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId);
                let result = [];
                if (item?.answer) {
                    result = item.answer.filter(o => o.valueCoding?.code).map(o => o.valueCoding.code);
                }

                return result;
            }

            const CareITAn_Sempa_Kind_1001 = ensureItem('CareITAn_Sempa_Kind_1001'); // "Auswahl Altersgruppe"
            const NIT_SVAnK_25 = ensureItem('NIT_SVAnK_25');
            const NIT_SVAnK_326 = ensureItem('NIT_SVAnK_326'); // "Benötigt das Kind Unterstützung über die Hilfe der Bezugspersonen hinaus?" - only visible when caregrade has been set
            const NIT_SVAnK_327 = getMultiSelectValues(anamnesis, 'NIT_SVAnK_327'); // "In welchen Bereichen hat PatientIn in der letzten 2 Wochen vor der Aufnahme Hilfe benötigt?" - MULTISELECT!, only visible when 326=326_00
            const NIT_SVAnK_328 = ensureItem('NIT_SVAnK_328'); // "Lebt PatientIn mit jemandem im gleichen Haushalt, der zu Hause (auch nach der Entlassung) helfen kann?" - only visible when caregrade has been set

            let sum = 0;
            switch (CareITAn_Sempa_Kind_1001) {
                case 'CareITAn_Sempa_Kind_1001_01':
                    // sum += 0;
                    break;
                case 'CareITAn_Sempa_Kind_1001_02':
                    sum += 1;
                    break;
                case 'CareITAn_Sempa_Kind_1001_03':
                    sum += 2;
                    break;
                case 'CareITAn_Sempa_Kind_1001_04':
                    sum += 3;
                    break;
                case 'CareITAn_Sempa_Kind_1001_05':
                    sum += 4;
                    break;
                case 'CareITAn_Sempa_Kind_1001_06':
                    sum += 5;
                    break;
            }

            if (NIT_SVAnK_328 === 'NIT_SVAnK_328_01')
                sum += 4;

            if (NIT_SVAnK_326 === 'NIT_SVAnK_326_00' && NIT_SVAnK_327?.length > 0) {
                sum += NIT_SVAnK_327.length;
            }

            switch (NIT_SVAnK_25) {
                case 'NIT_SVAnK_25_01':
                    sum += 1;
                    break;
                case 'NIT_SVAnK_25_02':
                    sum += 2;
                    break;
                case 'NIT_SVAnK_25_03':
                    sum += 3;
                    break;
                case 'NIT_SVAnK_25_04':
                    sum += 4;
                    break;
                case 'NIT_SVAnK_25_05':
                    sum += 5;
                    break;
            }

            result.sum = sum;
            if (sum < 8) {
                result.text1 = this.i18n.tr('risk_low');
            } else if (sum >= 8 && sum <= 15) {
                result.text1 = this.i18n.tr('risk_higher');
            } else if (sum > 15) {
                result.text1 = this.i18n.tr('risk_very_high');
            }

            if (typeof sum === "number")
                result.text1 = `${result.text1} / ${sum} Punkte`;
        } catch (e) {
            console.warn(e);
            result.text1 = e;
            result.color = this.colorRed;
        }

        return result;
    }

    protected override updateCustomRisks(patient: PatientItem, assessment: any) {
        const pavk = this.getPAVK();
        const ncsk = this.getNCSK(patient, assessment);

        if (pavk) {
            this.updateRisk(patient, 'risk_pav_k', pavk.sum, pavk.text1);
        }

        if (ncsk) {
            this.updateRisk(patient, 'risk_ncs_k_analysis', ncsk.sum, ncsk.text1);
            this.updateRisk(patient, 'risk_ncs_k_overview', ncsk.level, ncsk.info);

            // now override the patient carelevel stuff because we are in sempa-kids and not base sempa. so we have to get i from ncs-k
            PatientItem.UpdateCareLevel(patient, ncsk.level, assessment, ncsk.info, ncsk.color, ncsk.sum)
                .catch(error => {
                    console.warn(error);
                });
        }
    }

    protected generateHtmlRows(values: ISempaResult): string {
        let result = '';
        this.extraRows = '';
        const ageRange: IAgeRange = AnalyzerSempaKids.GetAgeRange(this.patient);

        if (ConfigService.Debug) {
            console.debug("Age Range is: " + ageRange);
        }

        const sturz = this.getSturz();

        switch (ageRange) {
            case IAgeRange.ZeroTo14Days: // should include GH
                result += this.generateRow(this.getKUSS());
                result += this.generateRow(this.getMobility());
                // #2549 - no more finnegan score in analysis:
                result += this.generateRow(this.getFinneganTableRow());
                result += this.generateRow(this.getRelationRow());
                result += this.generateRow(this.getPAVK());
                result += this.generateRow(values.eat);
                break;
            case IAgeRange.FifteenDaysTo1Year:
                result += this.generateRow(this.getKUSS());
                result += this.generateRow(this.getMobility());
                result += this.generateRow(this.getRelationRow());
                result += this.generateRow(this.getPAVK());
                result += this.generateRow(values.diabetes);
                result += this.generateRow(values.eat);
                break;
            case IAgeRange.OneTo5Years:
                result += this.generateRow(this.getKUSS());
                result += this.generateRow(sturz);
                result += this.generateRow(this.getPAVK());
                result += this.generateRow(values.diabetes);
                result += this.generateRow(values.decu);
                result += this.generateRow(values.pneumo);
                result += this.generateRow(this.getMobility());
                result += this.generateRow(values.eat); // dysphagie
                break;
            case IAgeRange.FiveTo12Years:
                result += this.generateRow(this.getKUSS());
                result += this.generateRow(sturz);
                result += this.generateRow(this.getPAVK());
                result += this.generateRow(values.diabetes);
                result += this.generateRow(values.decu);
                result += this.generateRow(values.pneumo);
                result += this.generateRow(this.getMobility());
                result += this.generateRow(values.eat);
                break;
            case IAgeRange.TwelveTo18Years:
                result += this.generateRow(this.getKUSS());
                result += this.generateRow(this.getPAVK());
                result += this.generateRow(values.diabetes);
                result += this.generateRow(values.decu);
                result += this.generateRow(values.pneumo);
                result += this.generateRow(sturz);
                result += this.generateRow(values.fall);
                result += this.generateRow(this.getMobility());
                result += this.generateRow(values.eat);
                break;
            case IAgeRange.Other:
                result += super.generateHtmlRows(values);
                break;
        }

        return result;
    }

    protected override calculatePoints(assessment: any, svg: SVGImageElement, targetPathId: string) {
        let targetPoints = [];

        //#region Körperpflege, g0, #p_?_0
        let resultKoerperPflege = -1;

        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_300')) {
            // use per default NIT_SVAsK_300..
            resultKoerperPflege = this.fieldToIntValue(assessment, 'NIT_SVAsK_300', 0); // Pflege OK
        } else {
            // .. if that does not exist fall back to default NIT_SVAs_300 handling
            resultKoerperPflege = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAs_300', 0), // Pflege OK
                this.fieldToIntValue(assessment, 'NIT_SVAs_301', 0)); // Pflege UK
        }
        targetPoints.push(`p_${resultKoerperPflege}_0`);
        //#endregion

        //#region Bewegung, g1, #p_?_1
        let sum1 = -1; // Mobilität
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_100')) {
            sum1 = this.fieldToIntValue(assessment, 'NIT_SVAsK_100', 0); // Mobilität
        } else {
            sum1 = this.fieldToIntValue(assessment, 'NIT_SVAs_100', 0); // Mobilität
        }
        targetPoints.push(`p_${sum1}_1`);
        //#endregion

        //#region An- und Auskleiden, g2, #p_?_2
        let sum2 = -1;
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_600')) {
            sum2 = this.fieldToIntValue(assessment, 'NIT_SVAsK_600', 0);
        } else {
            sum2 = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAs_600', 0), // Kleidung OK
                this.fieldToIntValue(assessment, 'NIT_SVAs_601', 0)); // Kleidung UK
        }

        targetPoints.push(`p_${sum2}_2`);
        //#endregion

        //#region Schmerzen, g3, #p_?_3
        let sum3 = -1;
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_202')) {
            sum3 = this.fieldToIntValue(assessment, 'NIT_SVAsK_202', 0);
        } else {
            sum3 = this.fieldToIntValue(assessment, 'NIT_SVAs_202', 0);
        }

        switch (sum3) {
            default:
                sum3 = 0;
                break;
            case 1:
            case 2:
            case 3:
                sum3 = 1;
                break;
            case 4:
            case 5:
            case 6:
                sum3 = 2;
                break;
            case 7:
            case 8:
            case 9:
            case 10:
                sum3 = 3;
                break;
        }

        targetPoints.push(`p_${sum3}_3`);
        //#endregion

        //#region Ess- und Trinkverhalten, g4, #p_?_4
        let sum4 = Math.max(
            this.fieldToIntValue(assessment, 'NIT_SVAs_400', 0), // Ernährungszustand
            this.fieldToIntValue(assessment, 'NIT_SVAs_403', 0), // Essen
            this.fieldToIntValue(assessment, 'NIT_SVAs_405', 0) // Trinken/Flüssigkeitsaufnahme
        );

        if (sum4 > 3)
            sum4 = 3;

        targetPoints.push(`p_${sum4}_4`);
        //#endregion

        //#region Ausscheidung, g5, #p_?_5
        let sum5 = -1;
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_510')) {
            const questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment?.questionnaire);

            const responseValue510K = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_510');
            const responseValue511K = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_511');

            const questionnaireItem510K = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAsK_510')
            const questionnaireItem511K = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAsK_511')
            const value510K = QuestionnaireResponse.GetOrdinalValue(responseValue510K, questionnaireItem510K);
            const value511K = QuestionnaireResponse.GetOrdinalValue(responseValue511K, questionnaireItem511K);

            sum5 = value510K + value511K;
        } else {
            sum5 = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAs_500', 0), // Toilettenbenutzung
                this.fieldToIntValue(assessment, 'NIT_SVAs_503', 0), // Umgang Harnausscheidung
                this.fieldToIntValue(assessment, 'NIT_SVAs_506', 0)  // Umgang Stuhlausscheidung
            );
        }

        targetPoints.push(`p_${sum5}_5`);
        //#endregion

        //#region Atmung, g6, #p_?_6
        const sum6 = this.fieldToIntValue(assessment, 'NIT_SVAs_201', 0);

        targetPoints.push(`p_${sum6}_6`);
        //#endregion

        //#region Schlafqualität, g7, #p_?_7
        let sum7 = -1;
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_700')) {
            sum7 = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAsK_700', 0), // Ruhen
                this.fieldToIntValue(assessment, 'NIT_SVAsK_701', 0), // Schlafen
            );
        } else {
            sum7 = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAs_700', 0), // Ruhe und Schlafen
                this.fieldToIntValue(assessment, 'NIT_SVAs_702', 0), // Verhältnis Ta-/Nacht Schlaf
                this.fieldToIntValue(assessment, 'NIT_SVAs_703', 0)  // Schlafhygiene
            );
        }

        targetPoints.push(`p_${sum7}_7`);
        //#endregion

        //#region Kommunikation, g8, #p_?_8
        let sum8 = 0;
        sum8 = Math.max(
            this.fieldToIntValue(assessment, 'NIT_SVAs_00', 0), // Hörfähigkeit
            this.fieldToIntValue(assessment, 'NIT_SVAs_01', 0), // Sehfähigkeit
            this.fieldToIntValue(assessment, 'NIT_SVAs_03', 0), // Fähigkeit, sich an einem Gespräch zu beteiligen
            this.fieldToIntValue(assessment, 'NIT_SVAs_05', 0)  // Hörfähigkeit
        );

        targetPoints.push(`p_${sum8}_8`);
        //#endregion

        //#region Beziehungsgestaltung, g9, #p_?_9
        let sum9 = 0;
        const value1301 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsK_1301');
        if (value1301) {
            sum9 = this.fieldToIntValue(assessment, 'NIT_SVAsK_1301', 0); // "Wie gestaltet sich Mutter-Kind Verhältnis?"
        } else {
            sum9 = Math.max(
                this.fieldToIntValue(assessment, 'NIT_SVAs_801', 0), // "Interaktion mit Personen im direkten Kontak.."
                this.fieldToIntValue(assessment, 'NIT_SVAs_902', 0), // Ist Nähe-Distanz-Empfinden krankheitsbedingt beeinflusst?
                this.fieldToIntValue(assessment, 'NIT_SVAs_1101', 0), // Umgang mit anderen Personen problemlos
                this.fieldToIntValue(assessment, 'NIT_SVAs_1203', 0) // Bestehende Beziehungen aufrechterhalten (Bekannte, Verwandte)
            );
        }

        targetPoints.push(`p_${sum9}_9`);
        //#endregion

        this.setSvgPoints(targetPathId, targetPoints, svg);
    }

    public override async GetSPISpider(patient: PatientItem): Promise<SVGImageElement> {
        let svg: SVGImageElement = await AnalyzerSEMPA.reloadSVGImage('analyzers/SEMPA-Kids/risk_chart_sempa_kids.svg');

        function togglePath(pathId: string, show: boolean) {
            const path: SVGPathElement = svg.querySelector('#' + pathId);
            if (path) {
                path.setAttribute('display', show ? 'inline' : 'none');
                path.style.display = show ? 'inline' : 'none';
            }
        }

        const assessmentQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(patient.getAssessmentName());
        const assessmentResponses = QuestionnaireService.GetResponsesOfType(patient, assessmentQuestionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        const assessment = assessmentResponses[assessmentResponses.length - 1];
        const pathPrevious = 'path_previous';
        const pathCurrent = 'path_current';
        if (assessment) {
            if (assessmentResponses[assessmentResponses.length - 2]) {
                try {
                    const previous = assessmentResponses[assessmentResponses.length - 2];
                    if (previous) {
                        this.calculatePoints(previous, svg, pathPrevious);
                        togglePath(pathPrevious, true);
                    } else
                        togglePath(pathPrevious, false);
                } catch (e) {
                    console.warn(e.message);
                    togglePath(pathPrevious, false);
                }
            } else {
                togglePath(pathPrevious, false);
            }


            try {
                this.calculatePoints(assessment, svg, pathCurrent);
                togglePath(pathCurrent, true);
            } catch (e) {
                console.warn(e.message);
                togglePath(pathCurrent, false);
            }
        } else {
            togglePath(pathCurrent, false);
            togglePath(pathPrevious, false);
        }

        return svg;
    }

    public override getNCSSpiderTextHtml(patient: PatientItem): string {
        try {
            let ncsK = '-';
            const ncskRisk = this.findRisk(patient, "/risk_ncs_k_overview");

            if (ncskRisk && ncskRisk.display) {
                ncsK = ncskRisk.display;
            }

            // add the NCA-S value
            const ncsKDiv = `<div class="col-xs-6"><label>NCS-K:</label><span>${ncsK}</span></div>`;

            return ncsKDiv;
        } catch (e) {
            console.warn(e);
            return `<span><b>${e}</b></span>`;
        }
    }

    protected override processRiskSunValues(svg: SVGSVGElement, patient: PatientItem, raCurrent: any, raPrevious: any) {
        patient.getAssessmentName();
        patient.getAnamnesisName();
        // debug: PatientItem.LastLoadedPatient.currentRisks.prediction.filter(o=>o.outcome).map(o=>o.outcome).filter(o=>o.coding && o.coding[0]).map(p=>p.coding[0])
        // or using parameter: patient.currentRisks.prediction.filter(o=>o.outcome).map(o=>o.outcome).filter(o=>o.coding && o.coding[0]).map(p=>p.coding[0])

        //#region helper functions
        const updateIndicator = function (element: SVGElement, deg: number, fillColor: string) {
            try {
                element.style.fill = fillColor;
                element.style.transformBox = 'fill-box';
                element.style.transformOrigin = 'center center';
                element.style.transform = 'rotate(' + deg + 'deg)';
            } catch (ex) {
                console.warn(ex);
            }
        }

        const updateIndicatorFromRisks = (riskName: string) => {
            try {
                const svgArrow: SVGPathElement = svg.querySelector(`#arr_${riskName}`);
                if (!svgArrow || !raCurrent?.prediction || !raPrevious?.prediction)
                    return;

                const curRisk = raCurrent.prediction.find(o => o.outcome && o.outcome.coding && o.outcome.coding[0] && o.outcome.coding[0].system.endsWith(riskName));
                const curCoding = curRisk ? curRisk.outcome.coding[0] : {
                    code: "0",
                    display: "placeholder for " + riskName
                };

                const prevRisk = raPrevious ? raPrevious.prediction.find(o => o.outcome && o.outcome.coding && o.outcome.coding[0] && o.outcome.coding[0].system.endsWith(riskName)) : curRisk;
                const prevCoding = prevRisk ? prevRisk.outcome.coding[0] : {
                    code: "0",
                    display: "placeholder for " + riskName
                };

                let curValue = parseInt(curCoding.code);
                if (!curValue || isNaN(curValue)) {
                    curValue = 0;
                }

                let prevValue = parseInt(prevCoding.code);
                if (!prevValue || isNaN(prevValue)) {
                    prevValue = 0;
                }

                const rotation = curValue == prevValue ? 0 : curValue < prevValue ? -90 : 90;

                svgArrow.style.fill = curValue == prevValue ? this.fillGrey : curValue < prevValue ? this.fillGreen : this.fillRed;
                ;
                svgArrow.style.transformBox = 'fill-box';
                svgArrow.style.transformOrigin = 'center center';
                svgArrow.style.transform = 'rotate(' + rotation + 'deg)';
            } catch (e) {
                console.warn(e);
            }
        }

        const updateArrowIndicatorFromAssessment = (indicatorSelector: string, linkIdRisk: string, linkIdAlternate: string) => {
            let indicatorColor = this.fillGrey;
            let indicatorRotation = 0;
            const indicatorPath: SVGPathElement = svg.querySelector(indicatorSelector);

            try {
                if (!indicatorPath) {
                    return;
                }

                let codeCurrent = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, linkIdRisk, undefined);
                if (!codeCurrent && linkIdAlternate) {
                    codeCurrent = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, linkIdAlternate, undefined);
                }

                if (!codeCurrent) {
                    // check whether the response item itself exists
                    const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(currentAssessment, linkIdRisk, false);
                    if (!responseItem) {
                        console.warn(`QuestionnaireResponse does not contain LinkId "${linkIdRisk}" !`);
                        // now check if the item exists in the questionnaire. Should be the case, but who knows..?
                        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(currentAssessment.questionnaire);
                        if (questionnaire) {
                            const qItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkIdRisk);
                            if (!qItem)
                                console.warn(`Questionnaire "${questionnaire.name}" (${questionnaire.id}) does not contain linkId "${linkIdRisk}"`);
                        }
                    } else {
                        console.warn(`QuestionnaireResponse does not contain a value for LinkId "${linkIdRisk}" ${linkIdAlternate ? "(or alternative " + linkIdAlternate : ""}! Consider setting a default value!`);
                    }
                }

                let codePrevious = QuestionnaireResponse.GetResponseItemValueByLinkId(prevAssessment, linkIdRisk, undefined) || codeCurrent;

                const intCurrent = QuestionnaireResponse.GetKeyValueAsInteger(codeCurrent);
                const intPrevious = QuestionnaireResponse.GetKeyValueAsInteger(codePrevious);
                if (!isNaN(intCurrent) && !isNaN(intPrevious)) {
                    if (intCurrent < intPrevious) {
                        indicatorColor = this.fillGreen;
                        indicatorRotation = -90;
                    } else if (intCurrent > intPrevious) {
                        indicatorColor = this.fillRed;
                        indicatorRotation = 90;
                    }
                }
            } catch (ex) {
                console.warn(ex);
                indicatorColor = 'transparent'; // do not display the value if something went wrong
            }

            updateIndicator(indicatorPath, indicatorRotation, indicatorColor);
        }
        //#endregion

        const anamnesis = QuestionnaireService.GetLatestResponseOfName(patient, patient.getAnamnesisName(), [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(patient.getAssessmentName());
        if (!questionnaireAssessment) {
            console.warn(`Questionnaire with name "${patient.assessmentName} not found`);
            return;
        }

        const assessments = QuestionnaireService.GetResponsesOfType(patient, questionnaireAssessment.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (assessments.length === 0) {
            return;
        }

        const currentAssessment = assessments[assessments.length - 1];

        let prevAssessment = assessments[assessments.length - 2];
        if (!prevAssessment) {
            prevAssessment = NitTools.Clone(currentAssessment);
        }

        // update the arrows on page 0 from risk values:
        updateIndicatorFromRisks("risk_sturz_sum")

        //Mobilität - NIT_SVAs_100
        updateArrowIndicatorFromAssessment('#indicator_mobility', 'NIT_SVAs_100', 'NIT_SVAsK_100');

        //#region Pain / Schmerzen
        let valueNIT_SVAsK_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, 'NIT_SVAsK_202', 'NIT_SVAs_202');
        if (!valueNIT_SVAsK_202) {
            // fall back to default pain scale of sempa assessment, or fall back to NIT_SVAs_202_999
            valueNIT_SVAsK_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, 'NIT_SVAs_202');

            // take the default value from the assessment
            if (!valueNIT_SVAsK_202) {
                valueNIT_SVAsK_202 = Questionnaire.GetDefaultQuestionnaireItemValueByLinkId(questionnaireAssessment, 'NIT_SVAs_202');
                if (!valueNIT_SVAsK_202) {
                    console.warn(`No default value for pain/schmerzen assigned in NIT_SVAsK_202`);
                }
            }
        }

        updateArrowIndicatorFromAssessment('#arr_risk_pain_sum', 'NIT_SVAsK_202', 'NIT_SVAs_202'); // update Arrow

        valueNIT_SVAsK_202 = QuestionnaireResponse.GetKeyValueAsInteger(valueNIT_SVAsK_202);
        if (typeof valueNIT_SVAsK_202 === "number") {
            const painOuter: SVGPathElement = svg.querySelector('#circle_risk_pain');
            if (painOuter) {
                let outerColor = this.fillGrey;
                if (valueNIT_SVAsK_202 < 999) { // 999 should be the default value and indicates, that no value could be retrieved
                    if (valueNIT_SVAsK_202 == 0) {
                        outerColor = this.fillGreen;
                    } else if (valueNIT_SVAsK_202 > 0 && valueNIT_SVAsK_202 <= 5) {
                        outerColor = this.fillOrange;
                    } else if (valueNIT_SVAsK_202 > 5) {
                        outerColor = this.fillRed;
                    }
                }

                painOuter.style.fill = outerColor;
            }
        }
        //#endregion

        //#region Diabetes Mellitus (page 0) - NIT_SVAn_806
        const txtDiabetes: SVGElement = svg.querySelector("#txt_Diabetes__Mellitus");
        if (txtDiabetes) {
            // default equals NIT_SVAn_806_02 - aka unknown
            let diabetesColor = this.fillGrey;
            let diabetesText = 'N.b.';

            const NIT_SVAn_806 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_806');
            if (NIT_SVAn_806 === 'NIT_SVAn_806_00') {
                diabetesText = this.i18n.tr('no');
                diabetesColor = 'black';
            } else if (NIT_SVAn_806 === 'NIT_SVAn_806_01') {
                diabetesText = this.i18n.tr('yes');
                diabetesColor = 'black';
            } else {
                diabetesText = '-';
            }

            txtDiabetes.textContent = diabetesText;
            txtDiabetes.style.fill = diabetesColor;
        }
        //#endregion

        //#region PAV-K Sum
        try {
            const txtPAVK: SVGTextElement = svg.querySelector('#txt_pav_k');
            let pavkValue: number = NaN;
            let circlePavK: SVGPathElement = svg.querySelector('#circle_risk_pav_k');
            let pavkCircleColor = this.colorGrey;
            const curPavKRisk = raCurrent?.prediction.find(o => o.outcome?.coding && o.outcome.coding[0] && o.outcome.coding[0].system.endsWith('/risk_pav_k'));

            if (curPavKRisk?.outcome?.coding && curPavKRisk.outcome.coding[0]) {
                const coding = curPavKRisk.outcome.coding[0];
                if (coding?.code) {
                    pavkValue = parseInt(coding.code);
                }
            }

            if (txtPAVK) {
                let pavkTxtTarget: SVGTSpanElement | SVGTextElement = txtPAVK.querySelector('tspan') || txtPAVK;
                if (pavkTxtTarget) {
                    pavkTxtTarget.textContent = '?';

                    if (!isNaN(pavkValue)) {
                        pavkTxtTarget.textContent = `${pavkValue}`;
                    }
                }
            }

            if (circlePavK) {
                try {
                    if (!isNaN(pavkValue)) {
                        if (pavkValue >= 8 && pavkValue <= 15) {
                            pavkCircleColor = this.colorOrange;
                        } else if (pavkValue > 15) {
                            pavkCircleColor = this.colorRed;
                        }
                    }

                    circlePavK.style.fill = pavkCircleColor;
                } catch (e) {
                    console.warn(e);
                }
            }

            if (!isNaN(pavkValue)) {
                const g0 = svg.querySelector('#grp_0');
                if (g0) {
                    g0.setAttribute('title', `PAV-K: ${pavkValue}`);
                }
            }
        }
        catch (e) {
            console.warn(e);
        }
        //#endregion

        //#region aktuelles sturzereignis als text von NIT_SVAs_110
        let fallTextContent = '-';
        const txtFall: SVGTextElement = svg.querySelector('#txt_current_fall');
        if (txtFall) {
            const txtFallSpan: SVGTSpanElement = txtFall.querySelector('tspan');
            if (txtFallSpan) {
                const NIT_SVAs_110 = QuestionnaireResponse.GetResponseItemValueByLinkId(patient.latestAssessment, 'NIT_SVAs_110');
                if (NIT_SVAs_110) {
                    fallTextContent = NIT_SVAs_110 === 'NIT_SVAs_110_00' ? this.i18n.tr('no') : this.i18n.tr('yes');
                } else {
                    fallTextContent = '-';
                }

                txtFallSpan.textContent = fallTextContent;
            }
        }
        //#endregion

        //#region Konsum Mutter aus NIT_SVAnK_2007, Ausgabe Ja/Nein -> txt_consume_mother
        const txtConsumeMother: SVGTSpanElement = svg.querySelector('#txt_consume_mother tspan');
        if (txtConsumeMother) {
            const NIT_SVAnK_2007 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAnK_2007');

            if (NIT_SVAnK_2007) {
                txtConsumeMother.textContent = NIT_SVAnK_2007 === 'NIT_SVAnK_2007_00' ? this.i18n.tr('no') : this.i18n.tr('yes');
            } else {
                txtConsumeMother.textContent = '-';
            }
        }
        //#endregion

        //#region Mutter-Kind Verhältnis aus NIT_SVAsK_1301, Ausgabe als Zahl -> txt_mother_child_relation
        const txtMotherChildRelation = svg.querySelector('#txt_mother_child_relation tspan');
        if (txtMotherChildRelation) {
            const NIT_SVAsK_1301 = QuestionnaireResponse.GetResponseItemValueByLinkId(patient.latestAssessment, 'NIT_SVAsK_1301');
            if (NIT_SVAsK_1301) {
                txtMotherChildRelation.textContent = QuestionnaireResponse.GetKeyValueAsInteger(NIT_SVAsK_1301).toString();
            } else {
                txtMotherChildRelation.textContent = '?';
            }
        }
        //#endregion

        //#region now hide the items that do not apply the specific age-range(s)
        const grayOutGroup = (groups: SVGGElement[]) => {
            if (!groups || groups.length === 0)
                return;

            for (const group of groups) {
                // circle in gray:
                const circleBackground: SVGEllipseElement = group.querySelector('ellipse');
                if (circleBackground) {
                    circleBackground.style.fill = this.colorLightGrey;
                }

                const circleLine: SVGPathElement = group.querySelector('path[id^="circle"]');
                if (circleLine) {
                    circleLine.style.fill = this.colorDarkGey;
                }

                const textElement: SVGTextElement = group.querySelector('text');
                if (textElement) {
                    const span: SVGTSpanElement = textElement.querySelector('tspan');
                    if (span)
                        span.textContent = '';
                    else
                        textElement.textContent = '';
                }

                const arrow: SVGPathElement = group.querySelector('path[id^="arr"]')
                if (arrow)
                    arrow.style.display = 'none';
            }
        }

        const switchAgeRangeSvgItems = (ageRange) => {
            const g0: SVGGElement = svg.querySelector('#grp_0'); // PAV-K
            const g1: SVGGElement = svg.querySelector('#grp_1'); // Dekubitus
            const g2: SVGGElement = svg.querySelector('#grp_2'); // Pneumo
            const g3: SVGGElement = svg.querySelector('#grp_3'); // Sturz
            const g4: SVGGElement = svg.querySelector('#grp_4'); // aktueller Sturz
            const g0_9: SVGGElement = svg.querySelector('#grp_0-9'); // Mutter-Kind Verhältnis
            const g5: SVGGElement = svg.querySelector('#grp_5'); // Konsum Mutter
            const g6: SVGGElement = svg.querySelector('#grp_6'); // Mobilität
            const g7: SVGGElement = svg.querySelector('#grp_7'); // Dysphagie
            const g8: SVGGElement = svg.querySelector('#grp_8'); // Schmerzen
            const g9: SVGGElement = svg.querySelector('#grp_9'); // Diabetes Mellitus

            // grayOutGroup([g0, g1, g2, g3, g4, g0_9, g5, g6, g7, g8, g9]);

            switch (ageRange) {
                case IAgeRange.Other:
                default:
                    break;
                case IAgeRange.ZeroTo14Days:
                    grayOutGroup([g1, g2, g3, g4, g9]);
                    break;
                case IAgeRange.FifteenDaysTo1Year:
                    grayOutGroup([g1, g2, g3, g4, g5]);
                    break;
                case IAgeRange.OneTo5Years:
                    grayOutGroup([g0_9, g5]);
                    break;
                case IAgeRange.FiveTo12Years:
                    grayOutGroup([g0_9, g5]);
                    break;
                case IAgeRange.TwelveTo18Years:
                    grayOutGroup([g0_9, g5]);
                    break;
            }
        }

        switchAgeRangeSvgItems(AnalyzerSempaKids.GetAgeRange(patient))
        //#endregion
    }

    /*    public override async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
            const result = await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);
            return result;
        } */
}

export enum IAgeRange {
    ZeroTo14Days = 0,
    FifteenDaysTo1Year = 1,
    OneTo5Years = 2,
    FiveTo12Years,
    TwelveTo18Years = 4,
    Other = 999
}
