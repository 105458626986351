import * as Fhir from "../../../../resources/classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import * as moment from "moment";
import {IFormSetting} from "../../../classes/IFormSettings";
import {QuestionnaireService} from "../../QuestionnaireService";
import {fhirEnums} from "../../../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {ConfigService} from "../../ConfigService";
import {PatientItem} from "../../../classes/Patient/PatientItem";

export class Epa2_3Nrs {

    private readonly patient: PatientItem;
    private readonly assessment: any;
    private readonly anamnesis: any;
    private formSettings: IFormSetting;
    /** value indicating whether NRS should be calculated even if BMI influencing factors are set. could be specified in settings.NRSCalcEvenIfBMIFactorsAreSet */
    private readonly NRSCalcEvenIfBMIFactorsAreSet;
    /** value indicating whether BMI influencing factors are set */
    private BMIFactorsAreSet = false;
    /** value specifying the value calculation period for malnutrition in days. recommended: 3 or 7 days. could be specified in settings.nrsCalculationPeriod */
    private readonly nrsCalculationPeriod;
    private i18n: I18N;
    private readonly spi: number;
    private readonly QuestionnaireAnamanesis: any;
    private outOfCalculationPeriod: boolean = false;

    constructor(patient: PatientItem, assessment: any, anamnesis: any, formSettings: IFormSetting, spi: number, i18n: I18N) {
        this.patient = patient;
        this.assessment = assessment;
        this.anamnesis = anamnesis;
        this.formSettings = formSettings;
        this.i18n = i18n;
        this.spi = spi;

        if (this.formSettings && this.formSettings.settings && typeof this.formSettings.settings.NRSCalcEvenIfBMIFactorsAreSet === "boolean") {
            this.NRSCalcEvenIfBMIFactorsAreSet = this.formSettings.settings.NRSCalcEvenIfBMIFactorsAreSet;
        } else {
            this.NRSCalcEvenIfBMIFactorsAreSet = false;
        }

        if (this.formSettings && this.formSettings.settings && typeof this.formSettings.settings.nrsCalculationPeriod === "number") {
            this.nrsCalculationPeriod = this.formSettings.settings.nrsCalculationPeriod;
        } else {
            this.nrsCalculationPeriod = 3;
        }

        this.QuestionnaireAnamanesis = QuestionnaireService.GetQuestionnaireDirect(QuestionnaireService.__listResult.QAnamnesisId);
    }

    //region Helper Functions
    private anaValue(linkId: string) {
        return Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(this.anamnesis, linkId);
    }

    private assValue(linkId: string) {
        return Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(this.assessment, linkId);
    }

    private areSPIValuesValid(assessment: any): boolean {
        let result = true;
        // let fields = ['01_01', '05_01',    '05_02',    '05_03',    '05_04',    '03_01',    '03_05',    '04_01',    '04_03'];
        let fields = ['E2_I_001', 'E2_I_009', 'E2_I_010', 'E2_I_011', 'E2_I_012', 'E2_I_013', 'E2_I_017', 'E2_I_024', 'E2_I_026'];
        fields.forEach(field => {
            let fieldValue = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, field, undefined);
            if (typeof fieldValue === "undefined") {
                if (ConfigService.Debug) {
                    console.warn(`Invalid fieldValue for field "${field}" ("${String(fieldValue)}")`);
                }

                result = false;
                return false;
            }
        })

        //#region when AC 2.3, LTC 1.0 or KIDS 2.0 field 'E2_I_082' exists and needs to be checked too. Determine by existence of field
        let E2_I_082 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_082', false);
        if (typeof E2_I_082 !== "undefined") {
            if (ConfigService.Debug) console.debug("Field 'E2_I_082' exists. Checking value too");
            let E2_I_082_int: number = NaN;
            // = Fhir.QuestionnaireResponse.GetResponseItemValueInt(ana_06_15);
            switch (E2_I_082) {
                case 'E3_I_0390':
                    E2_I_082_int = 4;
                    break;
                case 'E3_I_0389':
                    E2_I_082_int = 3;
                    break;
                case 'E3_I_0388':
                    E2_I_082_int = 2;
                    break;
                case 'E3_I_0387':
                    E2_I_082_int = 1;
                    break;
            }

            if (isNaN(E2_I_082_int) || E2_I_082_int < 0 || E2_I_082_int > 4) {
                if (ConfigService.Debug) console.debug(`06_15 is invalid ("${String(E2_I_082_int)}")`, E2_I_082);
                result = false;
            } else {
                if (ConfigService.Debug) console.debug(`06_15 seems to have valid value (${E2_I_082_int})`);
            }
        }
        //#endregion

        return result;
    }

    //endregion

    /** Subprocess1 - calculate "Nahrungsmenge" - 2.3 */
    private subprocess1(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            value: NaN,
            success: true,
            messages: []
        };

        let lastSavedDateAnamnesis = moment(this.anamnesis.meta.lastUpdated);
        let lastSavedDateAssessment = moment(this.assessment.meta.lastUpdated);
        let dayCount = lastSavedDateAnamnesis.diff(lastSavedDateAssessment, "d");
        if (dayCount < -1) dayCount *= -1;
        dayCount -= 1;

        if (dayCount > this.nrsCalculationPeriod) {
            result.success = false;
            result.messages.push(this.i18n.tr('nrs_not_in_calculation_period'))
            this.outOfCalculationPeriod = true;
            return result;
        }

        let E0_I_074 = this.anaValue('E0_I_074'); // Nahrungsmenge vor Aufnahme
        if (['E0_I_0024', 'E0_I_0023', 'E0_I_0022', 'E0_I_0021'].indexOf(E0_I_074) === -1) {
            result.success = false;
            result.messages.push(this.i18n.tr('nrs_food_quantity_invalid'));

            return result;
        }

        let questionnaireId = this.assessment.questionnaire.reference.split('/')[1];
        if (questionnaireId.indexOf('/_') > -1)
            questionnaireId = questionnaireId.split('/_')[0];

        let assessmentList = QuestionnaireService.GetResponsesOfType(this.patient, questionnaireId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        let value1: number = 0;
        let value2: number = 0;
        let LMSum: number = 0;
        let j = 0;
        let lTempValue: number = 0;
        for (let i = assessmentList.length; i > 0; i--) {
            let historicAssessment = assessmentList[i - 1];
            let E2_I_014 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(historicAssessment, "E2_I_014", undefined); // Nahrungsmenge (oral) E2_I_014, war 03_02
            let E2_I_055 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(historicAssessment, "E2_I_055", undefined); // Künstliche Ernährung/ verordnete Zusatzkost E2_I_055, war 03_13

            if (E2_I_014) {
                switch (E2_I_014) {
                    case 'E3_I_0034':
                        value1 = 4;
                        break;
                    case 'E3_I_0033':
                        value1 = 3;
                        break;
                    case 'E3_I_0032':
                        value1 = 2;
                        break;
                    case 'E3_I_0031':
                        value1 = 1;
                        break;
                    default:
                    case 'E3_I_0413':
                        value1 = 0;
                        break;
                }
            } else {
                value1 = 0;
            }

            if (E2_I_055) {
                switch (E2_I_055) {
                    case 'E3_I_0229':
                        value2 = 0;
                        break;
                    case 'E3_I_0230':
                        value2 = 1;
                        break;
                    case 'E3_I_0231':
                        value2 = 3;
                        break;
                }
            } else {
                value2 = 0;
            }

            // value1 = valueItem1 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem1) : 0;
            // value2 = valueItem2 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem2) : 0;
            LMSum = value1 + value2;
            if (LMSum > 4) LMSum = 4;
            lTempValue += LMSum;
            j++;

            if (j >= this.nrsCalculationPeriod) break; // max 3
        }

        if (assessmentList.length > 3) {
            lTempValue = lTempValue / (3 /* assessments */ + 1 /* admission */);
        } else {
            lTempValue = lTempValue / (assessmentList.length + 1); // < 3 assessments + 1 admission
        }

        if (lTempValue <= 1.5) {
            result.value = 3;
        } else if (lTempValue >= 1.6 && lTempValue <= 2.4) {
            result.value = 2;
        } else if (lTempValue >= 2.5 && lTempValue <= 3.4) {
            result.value = 1;
        }

        result.success = true;
        result.messages = undefined;

        return result;
    }

    /** Subprocess2 - calculate "BMI/Allg.Zust." - 2.3 */
    private subprocess2(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        if (this.spi >= 32) {
            result.value = 0;
        } else {
            let E0_I_005 = this.anaValue('E0_I_005') || this.anaValue('E0_I_0301');
            let E0_I_005_float = (typeof E0_I_005 !== 'undefined' || isNaN(E0_I_005)) ? parseFloat(E0_I_005) : 0;

            if (!E0_I_005_float || E0_I_005_float < 0) {
                if (!this.NRSCalcEvenIfBMIFactorsAreSet) {
                    result.messages.push(this.i18n.tr('nrs_00_05_invalid_value'));
                    result.success = false;
                    return result;
                } else {
                    this.BMIFactorsAreSet = true;
                }
            }

            if (E0_I_005_float < 18.5) { // Ja
                result.value = 3;
            } else if (E0_I_005_float >= 18.5 && E0_I_005_float <= 20.5) {
                result.value = 2;
            } else result.value = 1;
        }

        return result;
    }

    /** Subprocess3 - calculate "Gewichtsverlust" - 2.3 */
    private subprocess3(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        let E0_I_004 = this.anaValue('E0_I_004'); // Ungewollter Gewichtsverlust E0_I_004, war 00_04
        switch (E0_I_004) {
            case 'E0_I_0295':
                result.value = 0;
                break;
            case 'E0_I_0296':
                result.value = 1;
                break;
            case 'E0_I_0297':
                result.value = 2;
                break;
            case 'E0_I_0299':
                result.value = 3;
                break;
            default:
                result.success = false;
                result.messages.push(this.i18n.tr('nrs_00_04_invalid_value'));
                result.value = NaN;
                break;
        }

        return result;
    }

    /** SubProcess4 - calculate "erhöhter Bedarf/Stressmetabolismus" - 2.3 */
    private subprocess4(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        let ass_03_12 = this.assValue('E2_I_060'); // Energie-/ Nährstoffbedarf E2_I_060, war 03_12
        switch (ass_03_12) {
            case 'E3_I_0225':
                result.value = 3;
                break;
            case 'E3_I_0226':
                result.value = 2;
                break;
            case 'E3_I_0227':
                result.value = 1;
                break;
            case 'E3_I_0228':
                result.value = 0;
                break;
            default:
                result.value = NaN;
                result.success = false;
                result.messages.push(this.i18n.tr('nrs_03_12_invalid_value'));
        }

        return result;
    }

    /** SubProcess 5 - calculate "Alter und abschließende Berechnung" */
    private subprocess5(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        if (this.patient.years && this.patient.years >= 70) result.value = 1;

        return result;
    }

    public go(): INrsResult {
        if (ConfigService.Debug) console.debug('epa2.3 NRS test has started');
        //#region preflight check
        //#endregion

        let result: INrsResult = {
            linkIdSum: "risk_nrs_sum",
            linkIdIsRisk: "risk_nrs_calc",
            errorResult: undefined,  // riskOutCome =  sub1 + sub2 + sub3 + sub4 + sub5
            riskText: this.i18n.tr('nrs_risk_na'),     // message describing the result (n/a, yes, unlikely)
            riskSum: NaN, // 0 or 1
            isRisk: undefined
        }

        let E0_I_004 = this.anaValue('E0_I_004');  // Ungewollter Gewichtsverlust E0_I_004, war 00_04
        if (['E0_I_0295', 'E0_I_0296', 'E0_I_0297', 'E0_I_0299'].indexOf(E0_I_004) === -1) {
            result.errorResult = -99;
            result.info = `E0_I_004 has invalid value ("${String(E0_I_004)}")`;
            return result;
        }

        if (!this.areSPIValuesValid(this.assessment)) {
            result.errorResult = -98;
            result.info = `At least one SPI-field has an invalid value`;
            return result;
        }

        // check BMI values
        let ana_00_05 = this.anaValue('00_05');
        let E2_I_173 = this.anaValue('E2_I_173');

        if (!ana_00_05) {
            result.info = '00_05 (BMI Value) not found in Anamnesis';
            result.errorResult = -97;
            return result;
        }

        this.BMIFactorsAreSet = (typeof E2_I_173 !== "undefined") && (E2_I_173 !== 'E3_I_0705' /* Nein */);
        if (this.BMIFactorsAreSet && !this.NRSCalcEvenIfBMIFactorsAreSet) {
            result.info = '00_06 (BMI Factors) has been set in Anamnesis. NRSCalcEvenIfBMIFactorsAreSet=false';
            result.errorResult = -96;
            return result;
        }

        if (this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet && ConfigService.Debug)
            console.debug('BMIFactors are set, but NRSCalcEvenIfBMIFactorsAreSet is set to true. So continuing NRS Calc');

        let subProcess1 = this.subprocess1(); // Nahrungsmenge
        if (!subProcess1.success) {
            result.info = subProcess1.messages ? subProcess1.messages.join(',\n') : 'Subprocess1 failed';
            result.errorResult = -95;
        }

        let subProcess2 = this.subprocess2(); // BMI / Allg.zust.
        if (!subProcess2.success) {
            result.info = subProcess2.messages ? subProcess2.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -94;
        }

        let subProcess3 = this.subprocess3(); // Gesichtsverlust
        if (!subProcess3.success) {
            result.info = subProcess3.messages ? subProcess3.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -93;
        }

        let subProcess4 = this.subprocess4(); // Gesichtsverlust
        if (!subProcess4.success) {
            result.info = subProcess4.messages ? subProcess4.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -92;
        }

        let subProcess5 = this.subprocess5(); // Gesichtsverlust
        if (!subProcess5.success) {
            result.info = subProcess5.messages ? subProcess5.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -91;
        }

        if (!subProcess1.success || !subProcess2.success || !subProcess3.success || !subProcess4.success || !subProcess5.success) {
            result.errorResult = -90;
            result.riskSum = NaN;
            result.isRisk = false;
            result.riskSum = 0;

            return result;
        }

        let nrsProcess1_3Max = Math.max(subProcess1.value, subProcess2.value, subProcess3.value);
        result.riskSum = nrsProcess1_3Max + subProcess4.value + subProcess5.value;
        result.isRisk = result.riskSum >= 3;
        result.errorResult = 0;
        result.info = this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet ? this.i18n.tr('nrs_risk_addition') : undefined;
        result.riskText = this.i18n.tr(result.isRisk ? 'nrs_risk_yes' : 'nrs_risk_unlikely');

        // place info that bmi factors are set but customer wants to calculate nrs anyway
        if (result.isRisk && this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet) {
            result.riskText += ` <span class="nrs-additional-warning">${this.i18n.tr('nrs_risk_addition')}</span>`;
        }

        // place info why this can not be calculated (out of calculation period)
        if (this.outOfCalculationPeriod) {
            result.riskText += ` <span class="nrs-additional-warning">${this.i18n.tr('nrs_not_in_calculation_period')}</span>`;
        }

        if (ConfigService.Debug) {
            console.debug(`NRS Results:\n
                        ---------------------
                        SubProcess1: ${subProcess1.value} Pt
                        SubProcess2: ${subProcess2.value} Pt
                        SubProcess3: ${subProcess3.value} Pt
                        SubProcess4: ${subProcess4.value} Pt
                        SubProcess5: ${subProcess5.value} Pt
                        ---------------------
                        Max SP1-3  : ${nrsProcess1_3Max} Pt
                        =====================
                        NRS-Result:  ${result.riskSum} Pt
                        IsRisk:  ${result.isRisk}`);
        }
        result.riskSum = result.isRisk ? 1 : 0;

        /* from TModelRiskNRS:
            this.riskOutCome = sub1 + sub2 + sub3 + sub4 + sub5;
            if (this.riskOutCome >= 3) {
                this.riskCheckOutcome = 1;
                this.resultMessage = translations.translate("nrs_risk_yes");
            } else {
                this.riskCheckOutcome = 0;
                this.resultMessage = translations.translate("nrs_risk_unlikely");
            }
        */

        if (ConfigService.Debug) console.debug('V2 NRS Calc finished. Returning:', result);

        return result;
    }
}

export interface INrsSubProcessResult {
    success: boolean;
    messages: string[];
    value: number;
}

export interface INrsResult {
    linkIdSum: string;
    linkIdIsRisk: string;
    errorResult: number;  // riskOutCome =  sub1 + sub2 + sub3 + sub4 + sub5
    riskText: string;     // message describing the result (n/a, yes, unlikely)
    riskSum: number; // 0 or 1
    isRisk: boolean;
    info?: string;
}
